import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PatientIcon from '@material-ui/icons/HowToReg';

import { FormattedMessage, intlShape, formatMessage } from 'react-intl';

const preDefinedFields = {
  'personal': ['firstname', 'lastname', 'dob', 'sex'],
  'communication': ['tel'],
  'address': ['street', 'no', 'postal', 'city', 'country', ],
  'insurance': ['insurance', 'insuranceCardNo']
};

const styleTemplates = {
  'personal': {gridTemplateColumns: '2fr 2fr 1fr 1fr'},
  'address': {gridTemplateColumns: '3fr 1fr 1fr 2fr 1fr'}
};

const translateValues = {
  'metadata.personal.sex': ['male', 'female']
};

function CallMetadataSummary(props) {
  const metadataId = props.metadataId;
  const metadataField = props.metadata[metadataId];
  const hasValue = metadataId in props.values;
  const value = hasValue ? props.values[metadataId] : null;
  const isMultiple = metadataField.type === 'multiple';

  return hasValue ? (isMultiple ? value : [value]).map(ix => props.intl.formatMessage({
    id: "metadata." + metadataId + ".values." + ix
  })).join(', ')
  : '';
}

function CallMetadata(props) {
  const metadataId = props.metadataId;
  const metadataField = props.metadata[metadataId];
  const hasValue = metadataId in props.values;
  const value = hasValue ? props.values[metadataId] : null;

  const radios = metadataField.values[props.intl.locale].map((text, ix) => {
    const intlMessageId = "metadata." + metadataId + ".values." + ix;
    return <FormControlLabel key={ix}
      value={ix}
      checked={hasValue && (value + "") === (ix + "")}
      control={<Radio />} 
      label={<FormattedMessage id={intlMessageId} />}
    />;
  });

  const largeGridStyles = {display:'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr', gridAutoFlow: 'column'};
  const smallGridStyles = {display:'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'};
  const valueCount = metadataField.values[props.intl.locale].length;

  return (<RadioGroup
    name={metadataId}
    style={valueCount > 5 ? largeGridStyles : smallGridStyles}
    onChange={(e, v) => {
      props.onChange(metadataId, v);
    }}>
    {radios}
  </RadioGroup>);
}

function getFieldReadableValue(type, field, value, intl) {
  const translationKey = `metadata.${type}.${field}`;
  if (translationKey in translateValues && translateValues[translationKey].indexOf(value) !== -1) {
    value = intl.formatMessage({id: `${translationKey}.${value}`, defaultMessage: value});
  }
  return value || '';
}

function ClientMetadataSummary(props) {
  const values = props.values;

  if (values.personal) {
    const p = values.personal;
    return [p.dob, getFieldReadableValue('personal', 'sex', p.sex, props.intl)].filter(d => !!d).join(', ');
  }
  else {
    return '';
  }
}

function GetClientMetadataName(props) {
  const values = props.values;
  if (values.personal) {
    const p = values.personal;
    return [p.firstname, p.lastname].join(" ");
  }
  else {
    return props.intl.formatMessage({
      id: 'Supporter.App.Anonymous'
    });
  }
}

function ClientMetadata(props) {
  const config = props.metadata;
  const values = props.values;

  const sections = config.map(s => {
    let fields = (preDefinedFields[s.type] || Object.keys(values[s])).map(field => {
      const translationKey = `metadata.${s.type}.${field}`;
      const readableField = props.intl.formatMessage({id: translationKey, defaultMessage: field});
      const value = getFieldReadableValue(s.type, field, (values[s.id] || {})[field], props.intl)

      return <TextField
        margin="dense"
        key={field}
        label={readableField}
        fullWidth
        value={value}
        onInput={(e) => {
          const v = e.target.value;
          values[s.id] = Object.assign({}, values[s.id] || {}, {[field]: v});
          props.onChange(props.metadataId, values);
        }}
      />
    });

    return <div key={s.id} style={{display:'grid', gridGap: '10px', gridTemplateColumns:  'repeat(auto-fit, minmax(186px, 1fr))', ...styleTemplates[s.type]}}>
      {fields}
    </div>
  })

  return (<div>
    {sections}
  </div>);
}

class MetadataInquiry extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    expanded: true
  };

  handleExpandChange(ex) {
    this.setState({expanded: ex});
  }

  render() {
    const props = this.props;
    const metadataId = this.props.metadataId;
    const metadataField = props.metadata[metadataId];
    const metadataIcon = metadataField && metadataField.icon ? <metadataField.icon /> : <PatientIcon />;
    const clientMetadata = metadataId === 'clientmetadata';
    const hasValue = !clientMetadata ? metadataId in props.values : !!Object.values(props.values).length;

    return <Card ref="card"
      className="inquiry"
      style={{textAlign:'left', display:'inline-block'}}>
      <CardHeader
        className={"metadata-inquiry-header" + (hasValue ? ' filled' : '')}
        title={clientMetadata ? GetClientMetadataName({...props, intl: this.context.intl}) : <FormattedMessage
          id={"metadata." + metadataId + ".title"}
          defaultMessage={metadataId}
        />}
        action={
          <IconButton aria-label="Expand" onClick={() => this.handleExpandChange(!this.state.expanded)}>
            {this.state.expanded && <ExpandLess />}
            {!this.state.expanded && <ExpandMore />}
          </IconButton>
        }
        style={{alignItems: 'flex-start'}}
        subheader={clientMetadata ? ClientMetadataSummary({...props, intl: this.context.intl}) : CallMetadataSummary({...props, intl: this.context.intl})}
        avatar={metadataIcon}
        titleTypographyProps={{color: 'inherit', style: {fontSize:'100%'}}}
        subheaderTypographyProps={{color: "inherit"}}
      />
      {this.state.expanded && <CardContent>
        {clientMetadata && <ClientMetadata {...props} {...{metadataId, intl: this.context.intl}} />}
        {!clientMetadata && <CallMetadata {...props} {...{metadataId, intl: this.context.intl}} />}
      </CardContent>}
    </Card>;
  }
};

export default MetadataInquiry;