
import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import VoiceChat from '@material-ui/icons/VoiceChat';
import Group from '@material-ui/icons/Group';
import Info from '@material-ui/icons/InfoOutlined';
import Fab from '@material-ui/core/Fab';
import CallIcon from '@material-ui/icons/Call';
import CallEnd from '@material-ui/icons/CallEnd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ContentSend from '@material-ui/icons/Send';
import OpenInNew from '@material-ui/icons/OpenInNew';
import FileCopy from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button'
import EmailIcon from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dot from '@material-ui/icons/FiberManualRecord';
import TextField from '@material-ui/core/TextField'
import Popper from '@material-ui/core/Popper'
import Grow from '@material-ui/core/Grow' 
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CardHeader from '@material-ui/core/CardHeader'
import Tooltip from '@material-ui/core/Tooltip'
import Chip from '@material-ui/core/Chip'
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';

import Chat from './shared/Chat';
import CallScreen from './shared/CallScreen';
import CallStatus from './shared/CallStatus';
import ContactInfo from './ContactInfo';
import { sendConversation } from './SaveConversation'
import { getBusyStateFromCallState } from './LiveView'

const Colors = require('@material-ui/core/colors');
const greenA700 = '#009a3c'

const localeMessages = defineMessages({
  appointment: {
    id: 'Supporter.AppointmentForm.Appointment',
    defaultMessage: 'Ihr Online-Termin mit {name}'
  },
  clickchat: {
    id: 'Supporter.AppointmentForm.ClickChat',
    defaultMessage: 'Zum chatten hier klicken: '
  },
  startchat: {
    id: 'Supporter.AppointmentForm.StartChat',
    defaultMessage: 'Chat starten'
  },
  cancel: {
    id: 'Supporter.AppointmentForm.Cancel',
    defaultMessage: 'Abbrechen'
  },
  sendinvite: {
    id: 'Supporter.AppointmentForm.SendInvite',
    defaultMessage: 'Einladung senden'
  },
  sendtoself: {
    id: 'Supporter.AppointmentForm.SendToSelf',
    defaultMessage: 'Link an meine E-Mail-Adresse senden'
  },
  sendtoselfbutton: {
    id: 'Supporter.AppointmentForm.SendToSelfButton',
    defaultMessage: 'Senden'
  },
  copybutton: {
    id: 'Supporter.AppointmentForm.CopyButton',
    defaultMessage: 'Kopieren'
  },
  copybuttonTooltip: {
    id: 'Supporter.AppointmentForm.CopyButtonTooltip',
    defaultMessage: 'Link in die Zwischenablage kopieren'
  },
  previewAnonymousLinkTooltip: {
    id: 'Supporter.AppointmentForm.PreviewAnonymousLinkTooltip',
    defaultMessage: 'Vorschau aus Kundensicht anzeigen'
  },
  previewAnonymousLink: {
    id: 'Supporter.AppointmentForm.PreviewAnonymousLink',
    defaultMessage: 'Vorschau'
  },
  choosedate: {
    id: 'Supporter.AppointmentForm.ChooseDate',
    defaultMessage: 'Wählen Sie ein Datum'
  },
  choosetime: {
    id: 'Supporter.AppointmentForm.ChooseTime',
    defaultMessage: 'Wählen Sie eine Uhrzeit'
  },
  ok: {
    id: 'Supporter.AppointmentForm.OK',
    defaultMessage: 'OK'
  },
  sendinvitemail: {
    id: 'Supporter.AppointmentForm.SendInviteMail',
    defaultMessage: 'Einladung versenden'
  },
  mailrecipient: {
    id: 'Supporter.AppointmentForm.MailRecipient',
    defaultMessage: 'Empfänger'
  },
  mailsubject: {
    id: 'Supporter.AppointmentForm.MailSubject',
    defaultMessage: 'Betreff'
  },
  mailremarks: {
    id: 'Supporter.AppointmentForm.MailRemarks',
    defaultMessage: 'Spezielle Anmerkungen'
  },
  mailpreview: {
    id: 'Supporter.AppointmentForm.MailPreview',
    defaultMessage: 'E-Mail Vorschau'
  },
  groupCountOnline: {
    id: 'Supporter.Conversation.GroupCountOnline',
    defaultMessage: '{count, plural, =0 {Kein Teilnehmer} one {1 Teilnehmer} other {# Teilnehmer}} online'
  },
  online: {
    id: 'Supporter.Conversation.Online',
    defaultMessage: 'Online'
  },
  offline: {
    id: 'Supporter.Conversation.Offline',
    defaultMessage: 'Offline'
  },
  startGroupChat: {
    id: 'Client.Lobby.JoinGroup',
    defaultMessage: 'Gruppenchat beitreten'
  },
  startChat: {
    id: 'Supporter.Conversation.StartChat',
    defaultMessage: 'Anruf starten'
  },
  stopGroupChat: {
    id: 'Supporter.Conversation.StopGroupChat',
    defaultMessage: 'Gruppenchat beenden'
  },
  stopChat: {
    id: 'Supporter.Conversation.StopChat',
    defaultMessage: 'Anruf beenden'
}
});

class ConversationWindow extends Component {

  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    infoShown: false,
    callMoreMenuShown: false
  }

  constructor(props) {
    super(props);

    this.linkText = React.createRef();
    this.callContainer = React.createRef();
    this.callMoreRef = React.createRef();
  }

  sendInviteToUser() {
    this.props.sendInviteToUser(this.props.getInviteLink(null, null, this.props.conversation.name), {withGroup: true, groupName: this.props.conversation.name});
  }

  copyLink() {
    this.props.copyLink(this.linkText.current);
  }

  preview(e, url) {
    this.props.preview(e.screenX, e.screenY, url);
  }

  handleCallMenuClose(event) {
    if (this.callMoreRef.current && this.callMoreRef.current.contains(event.target)) {
      return;
    }

    this.setState({callMoreMenuShown: false});
  }
  
  render() {
    const hasConversation = this.props.callStatus === 'calling' || (this.props.calling && this.props.calling.id === this.props.conversation.id);
    const showCallStatus = ['stopped', 'recovering', 'calling', 'stopping', 'accepted', 'denied'].indexOf(this.props.callStatus) !== -1;
    const contactInfos = this.props.conversation.contacts.concat(Object.values(this.props.conversation.offlineContacts)).map(contact => {
      return (
        <ContactInfo
          key={contact.id}
          contact={contact}
          locale={this.props.locale}
          clientMetadata={this.props.clientMetadata}
          callMetadata={this.props.callMetadata}
          callMetadataValues={this.props.callMetadataValues}
          onChangeCallMetadata={this.props.onChangeCallMetadata}
        />);
    });

    const handleListKeyDown = (event) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        this.setState({callMoreMenuShown: false});
      }
    };

    const hasOnlineGroups = !!this.props.groupUsers && !!this.props.groupUsers.length;
    const onlineGroupUsers = (this.props.groupUsers || []).filter(u => u.online && u.id !== this.props.user.id);

    return (
      <Card elevation={0} className={"contactWindow" + (this.state.infoShown ? ' infoshown' : '')}
        style={{
          backgroundColor: 'transparent',
          flex:1,
          display: 'flex',
          flexDirection: 'column',
          overflow:'hidden'
        }}>
        <div className="contactheader" style={{
          display:'flex',
          alignItems: 'center'
        }}>
          <div className="name" style={{display:'flex'}}>
            {this.props.conversation.group && <Group htmlColor='#bdbdbd' style={{
              display:'inline-block', 
              marginRight: '16px', 
              width:'40px',
              height:'40px'}} />}
            {!this.props.conversation.group && <Avatar
              style={{display:'inline-block', marginRight: '16px'}}
            >
              <AccountCircle style={{width:'100%', height:'100%'}} />
            </Avatar>}
            <div style={{display:'inline-block'}}>
              <div style={{display:'inline-block'}}>{this.props.conversation.name}</div>
              <div style={{marginTop:'0.2em'}}>
                <span style={{color: (this.props.conversation.online ? greenA700 : 'lightgrey')}}>
                  {this.props.conversation.group ? this.context.intl.formatMessage(localeMessages.groupCountOnline, {count: this.props.conversation.contacts.length}) : ''}
                  {!this.props.conversation.group &&
                    (this.props.conversation.online ? this.context.intl.formatMessage(localeMessages.online) : this.context.intl.formatMessage(localeMessages.offline))}
                </span>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'left', flex:1, marginLeft: '90px'}}>
              { !hasConversation && this.props.conversation.online &&
              <Fab
                onClick={() => this.props.onCall(this.props.contact)}
                elevation={1}
                size="small"
                variant="extended"
                disabled={!this.props.conversation.online}
                style={{color:'white', padding: '0 1em'}}
                color="primary">
                <CallIcon htmlColor='white' style={{paddingRight: '0.5em'}} />
                { this.props.conversation.group && ' ' + this.context.intl.formatMessage(localeMessages.startGroupChat)}
                { !this.props.conversation.group && ' ' + this.context.intl.formatMessage(localeMessages.startChat)}
              </Fab>}
            { hasConversation &&
              <Fab
                onClick={() => {
                  if (this.props.callStatus !== 'calling') {
                    this.props.onStopCall();
                  }
                  else {
                    this.props.onAbortCall();
                  }
                }}
                elevation={1}
                size="small"
                variant="extended"
                style={{backgroundColor: '#cc0000', color:'white', padding: '0 1em'}}>
                <CallEnd htmlColor='white' style={{paddingRight: '0.5em'}} />
                { this.props.conversation.group && ' ' + this.context.intl.formatMessage(localeMessages.stopGroupChat)}
                { !this.props.conversation.group && ' ' + this.context.intl.formatMessage(localeMessages.stopChat)}
              </Fab>}
              {!this.props.conversation.group && <Button
                ref={this.callMoreRef}
                onClick={() => this.setState({callMoreMenuShown: !this.state.callMoreMenuShown})}
                size="small"
                color="default"
                variant="outlined"
                style={{margin:'0 1em', padding: '4px', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius:'2em', minWidth: 'auto'}}>
                <MoreHorizIcon />
              </Button>/*<IconButton
                ref={this.callMoreRef}
                onClick={() => this.setState({callMoreMenuShown: !this.state.callMoreMenuShown})}
                elevation={0}
                size="small"
                variant="outlined"
                style={{ margin: '0 0.5em', width: '34px', height: '34px', backgroundColor: 'rgba(0, 0, 0, 0.18)', boxShadow:'none'}}
                color="default">
                <MoreHorizIcon htmlColor="#666" />
              </IconButton>*/}
              <Popper style={{zIndex:2}} open={this.state.callMoreMenuShown} anchorEl={this.callMoreRef.current} placement="bottom-start" transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left top' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={e => this.handleCallMenuClose(e)}>
                        <div>
                          <List id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <ListItem alignItems="flex-start">
                              <ListItemAvatar>
                                <DoubleArrowIcon htmlColor="rgba(0, 0, 0, 0.54)" />
                              </ListItemAvatar>
                              <div>
                                <p style={{margin: '12px 0 0 0'}}>
                                  <FormattedMessage
                                    id='Supporter.Forwarding.Title'
                                    defaultMessage='Weiterleiten an'
                                  />
                                </p>
                                <List style={{display:'flex', maxHeight: '50vh', marginLeft: '-8px', overflow: 'auto', flexDirection: 'column', alignItems: 'flex-start'}}>
                                  {hasOnlineGroups && onlineGroupUsers.map(u => {
                                    return (
                                    <Tooltip key={u.id} enterDelay={300} title={u.email} placement="right">
                                      <ListItem style={{padding: '8px'}} button alignItems="flex-start" onClick={e => {
                                        this.handleCallMenuClose(e);
                                        this.props.forwardContact(this.props.conversation, u);
                                      }}>
                                        <ListItemIcon style={{minWidth: 'auto', marginRight: '4px'}}><Dot className="state" htmlColor={getBusyStateFromCallState(u.callStatus) ? '#e2b35b' : greenA700} /></ListItemIcon>
                                        <ListItemText style={{margin:0}} primary={<React.Fragment>
                                          <Typography
                                            component="span"
                                            variant="body2"
                                            color="textPrimary"
                                          >{u.name}</Typography></React.Fragment>}/>
                                      </ListItem>
                                    </Tooltip>);
                                  })}
                                  {hasOnlineGroups && onlineGroupUsers.length === 0 && <ListItem style={{padding: '8px'}} key={'none'} button disabled>
                                    <FormattedMessage
                                      id='Supporter.Forwarding.NoneOnline'
                                      defaultMessage='Kein Teammitglied online'
                                    />
                                  </ListItem>}
                                  {!hasOnlineGroups && <ListItem style={{padding: '8px'}} key={'none'} button disabled>
                                    <FormattedMessage
                                      id='Supporter.Forwarding.NotAvailable'
                                      defaultMessage='Teamfunktionen nicht verfügbar'
                                    />
                                  </ListItem>}
                                </List>
                              </div>
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={e => {
                              this.handleCallMenuClose(e);
                              sendConversation({
                                user: this.props.user,
                                sendMail: (o) => this.props.sendMail(o),
                                events: this.props.events,
                                conversation: this.props.conversation
                              }, this.context.intl)
                            }}>
                              <ListItemIcon><EmailIcon /></ListItemIcon>
                              <ListItemText>
                                <FormattedMessage
                                  id='Supporter.SaveConversation.Send'
                                  defaultMessage='Gesprächsdaten zusenden'
                                />
                              </ListItemText>
                            </ListItem>
                          </List>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
          </div>
          {this.props.conversation.group && <div style={{textAlign: 'left', flex: 1, display:'inline-flex', marginRight: '0.5em'}}>
            <div style={{flex:1}}>
              <TextField
                ref={this.linkText}
                name="invitelink"
                value={this.props.getInviteLink(null, null, this.props.conversation.name)}
                onClick={(e) => e.target.select()}
                fullWidth
                InputProps={{style: {textAlign:'center'}}}
              />
            </div>
            <div>
              <FormControl>
                <Tooltip title={this.context.intl.formatMessage(localeMessages.copybuttonTooltip)}>
                  <Button
                    variant="contained"
                    onClick={() => this.copyLink()}
                    color="primary"
                    variant="outlined"
                    style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
                  >
                    <FileCopy style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                  </Button>
                </Tooltip>
              </FormControl>
              <FormControl>
                <Tooltip title={this.context.intl.formatMessage(localeMessages.sendtoself)}>
                  <Button
                    variant="contained"
                    onClick={() => this.sendInviteToUser()}
                    color="primary"
                    variant="outlined"
                    style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
                  >
                    <ContentSend style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                  </Button>
                </Tooltip>
              </FormControl>
              <FormControl>
                <Tooltip title={this.context.intl.formatMessage(localeMessages.previewAnonymousLinkTooltip)}>
                  <Button
                    variant="contained"
                    onClick={(e) => this.preview(e, this.props.getInviteLink(null, null, this.props.conversation.name))}
                    color="primary"
                    variant="outlined"
                    style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
                  >
                    <OpenInNew style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                  </Button>
                </Tooltip>
              </FormControl>
            </div>
          </div>}
          <div className="infoButton" style={{textAlign: 'right', position:'relative'}}>
            <Info
              style={{width:'32px', height:'32px', opacity:0.75, fill: (this.state.infoShown ? 'rgb(0, 120, 154)' : '#999'), cursor:'pointer'}}
              onClick={() => this.setState({infoShown: !this.state.infoShown})} />
          </div>
        </div>
        <CardContent ref={this.callContainer} style={{
          display:'flex',
          backgroundColor: 'white',
          flex: 1,
          position:'relative',
          padding:0,
          overflow: 'hidden'
          }}>
          <div className="chatcontainer" style={{
            overflow: 'hidden'
          }}>
            <Chat
              sendMail={this.props.sendMail}
              local={this.props.user}
              remotes={this.props.conversation.contacts}
              onMessage={(msg) => this.props.onMessage(this.props.conversation, msg)}
              messages={this.props.messages}
              events={this.props.events.filter(e => this.state.infoShown ? true : (e.type !== 'online' && e.type !== 'offline') )}
              onMetadataChange={this.props.onChangeCallMetadata}
              metadataInquiries={this.props.metadataInquiries}
              onMessagesUpdate={(messages) => this.props.onMessagesUpdate(messages)}
            />
          </div>
          <div
            className={"callscreencontainer" + (showCallStatus ? ' hasOverlay':'')}
            style={{overflow: 'hidden', position: 'relative'}}>
            <CallScreen
              onToggleFullScreen={() => this.props.toggleFullscreen()}
              useJitsi={this.props.useJitsi}
              onError={(kind) => this.props.onError(kind)}
              onMuteRemote={() => this.props.onMuteRemote()}
              onMuteLocal={() => this.props.onMuteLocal()}
              onStopCall={() => this.props.onStopCall()}
              onDisableCam={() => this.props.onDisableCam()}
              onDisableDesktop={() => this.props.onDisableDesktop()}
              disableDesktop={this.props.disableDesktop}
              onSwitchCam={() => this.props.onSwitchCam()}
              onSelectSpeaker={(id) => this.props.onSelectSpeaker(id)}
              onSelectCam={(id) => this.props.onSelectCam(id)}
              onSelectMic={(id) => this.props.onSelectMic(id)}
              optimizeVideo={this.props.optimizeVideo}
              currentSpeaker={this.props.currentSpeaker}
              currentMic={this.props.currentMic}
              currentCam={this.props.currentCam}
              muteRemote={this.props.muteRemote}
              muteLocal={this.props.muteLocal}
              disableCam={this.props.disableCam}
              local={this.props.user}
              remotes={this.props.conversation.contacts}
              audioDevices={this.props.audioDevices}
              videoDevices={this.props.videoDevices}
              speakerDevices={this.props.speakerDevices}
              callStatus={this.props.callStatus}
              onStreamsRunning={this.props.onStreamsRunning}
              conversation={this.props.conversation}
              hasConversation={hasConversation}
            />
            {showCallStatus &&
              <CallStatus
                callStatus={this.props.callStatus}
                onResetCallStatus={() => this.props.clearCallStatus()}
                onStopCall={() => this.props.onStopCall()}
                onAbortCall={() => this.props.onAbortCall()}
              />
            }
          </div>
          {this.props.conversation.group && <List
              dense
              style={Object.assign({}, {
                borderLeft: '1px solid #c1bebb',
                transition: 'all 0.3s ease'
              },
                (showCallStatus || this.props.callStatus) ? {
                  position: 'absolute',
                  right: 0,
                  top: '1em',
                  borderLeft: 'none',
                  zIndex: 1002,
                  color: 'white'
                } : {})}
              className="contactsOnline">
              {this.props.conversation.contacts.map((c, ix) => {
                const established = this.props.callStatus && c.stream && c.stream.active && c.stream.getTracks().some(t => t.readyState === 'live');
                return <ListItem
                    dense
                    key={c.id}
                    style={{cursor:'pointer'}}
                    onClick={() => this.props.reconnect(c)}
                  >
                    <ListItemAvatar>
                      <Avatar style={{backgroundColor: established ? greenA700 : ''}}>
                        {!this.props.callStatus && <AccountCircle style={{width: '100%', height: '100%'}}/>}
                        {this.props.callStatus && !established && <MoreHoriz style={{width: '100%', height: '100%'}}/>}
                        {this.props.callStatus && established && <VoiceChat style={{}}/>}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={c.id} primary={c.name}
                      secondary={<span style={{opacity:0.8, transition: 'all 0.3s ease', color:(showCallStatus || this.props.callStatus) ? 'white' : 'inherit', fontSize: '0.9em', whiteSpace: 'nowrap'}}>
                        {(c.agent ? Object.values(c.agent.browser.name) : '')} {(c.agent ? Object.values(c.agent.os.name) : '')}</span>} />
                  </ListItem>;
              })}
            </List>}
          <div className="infoContainer">
            {contactInfos}
          </div>
        </CardContent>
      </Card>
    )
  }
};

export default ConversationWindow;
