import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar'
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

const localeMessages = defineMessages({
  firstContact: {
    id: "Supporter.Help.TechnicalContact",
    defaultMessage: "Technischer Kontakt (Erstkontakt)"
  },
  secondContact: {
    id: "Supporter.Help.TechnicalContact2",
    defaultMessage: "Technischer Kontakt (Zweitkontakt)"
  },
});

class Help extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    return (
      <div>
        <div className="">
          <h2>
            <FormattedMessage
              id='Supporter.Help.Help'
              defaultMessage="Hilfe &amp; Support"
            />
          </h2>
          <p>
            <FormattedMessage
              id='Supporter.Help.HelpInstructions'
              defaultMessage="Bei Problemen versuchen Sie folgende Erste-Hilfe-Massnahmen:"
            />
          </p>
          <ol>
            <li>
              <FormattedMessage
                id='Supporter.Help.ReloadInstructions'
                defaultMessage="Laden Sie die Seite neu und loggen sich ein:"
              /> <a href="/" onClick={(e) => { e.preventDefault(); window.location.reload()}}>
                <FormattedMessage
                  id='Supporter.Help.Reload'
                  defaultMessage="Seite neu laden"
                /></a></li>
            <li>
              <FormattedMessage
                id='Supporter.Help.NetworkInstructions'
                defaultMessage="Prüfen Sie Ihre Internetverbindung, dass Ihre Webcam angeschlossen ist und Ihre Lautsprecher laut genug eingestellt sind."
              />
            </li>
            <li>
              <FormattedMessage
                id='Supporter.Help.CoverInstructions'
                defaultMessage="Stellen Sie sicher, dass die Abdeckung Ihrer Webcam (falls vorhanden) offen ist bzw. die Webcam freie Sicht hat."
              />
            </li>
          </ol>
        </div>
        <div className="helpscreen" style={{marginTop: '30px'}}>
          <h3>
            <FormattedMessage
              id='Supporter.Help.DirectHelp'
              defaultMessage="Direkthilfe"
            />
          </h3>
          <p>
            <FormattedMessage
              id='Supporter.Help.CallInstructions'
              defaultMessage="Sollten Sie dennoch Probleme oder andere Fragen haben, kontaktieren Sie uns:"
            />
          </p>
          <div style={{display:'flex'}}>
            <Card style={{marginRight: '15px', flex: 1}}>
              <CardHeader
                title="Christof von Waldkirch"
                subheader={this.context.intl.formatMessage(localeMessages.firstContact)}
                avatar={<Avatar>
                  <AccountCircle />
                </Avatar>}
              />
              <CardContent>
                <p><a href="mailto:christof@vonwaldkirch.com">christof@vonwaldkirch.com</a></p>
                <p><a href="tel:0041764292480">+41 76 429 24 80</a></p>
              </CardContent>
            </Card>
            <Card style={{marginLeft: '15px', flex: 1}}>
              <CardHeader
                title="Dominique Sandoz"
                subheader={this.context.intl.formatMessage(localeMessages.secondContact)}
                avatar={<Avatar style={{flex: 0}}>
                  <AccountCircle />
                </Avatar>}
              />
              <CardContent>
                <p><a href="mailto:dominique.sandoz@vonwaldkirch.com">dominique.sandoz@vonwaldkirch.com</a></p>
                <p><a href="tel:0041786624350">+41 78 662 43 50</a></p>
              </CardContent>
            </Card>
            
          </div>
        </div>
      </div>
    )
  }
};

export default Help;
