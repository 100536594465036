import React, { Component } from 'react';

import './Streams.css'
import { Conversation } from '../App';

import { getTrackId } from './utils';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class Tracks extends Component {
  isAnimating = false;
  liveContacts = [];
  pipBigScreen = false;

  state = {
    big: 'local',
    zoomed: false,
    stats: {},
    localStream: "",
    pip: null
  }

  setBigScreen(kind) {
    this.setState({big: kind});

    const selectedTrack = this.props.conversation.tracks.find(t => t.getId() === kind);
    if (selectedTrack && !selectedTrack.isLocal() && this.props.conversation.room) {
      console.log("Setting selected track", selectedTrack.getId());
      this.props.conversation.room.selectParticipant(selectedTrack.getParticipantId());
    }
  }

  getStreamsOnline() {
    return this.props.trackCount;
  }

  toggleZoom() {
    this.setState({
      zoomed: !this.state.zoomed
    });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.bigPip !== this.props.bigPip) {
      this.pipBigScreen = true;
    }
    return true;
  }

  componentDidUpdate() {
    if (this.props.callStatus !== 'established' && this.getStreamsOnline() > 1) {
      this.props.onStreamsRunning();
    }

    let mainScreen = this.state.big;

    const liveStreams = this.props.conversation.tracks.filter(r => r.stream && r.getType() === 'video' && r.stream.active && r.stream.getTracks().some(t => t.readyState === 'live'));

    const nonLocalStreams = liveStreams.filter(s => !s.isLocal());

    if (mainScreen !== 'local' && !liveStreams.find(s => s.getId() === mainScreen)) {
      // mainScreen points to an invalid stream
      if (liveStreams.length > 0) {
        mainScreen = liveStreams[liveStreams.length - 1].getId();
      }
      else {
        mainScreen = 'local';
      }
    }
    else if (mainScreen === 'local' && nonLocalStreams.length > 1) {
      mainScreen = nonLocalStreams[nonLocalStreams.length - 1].getId();
    }

    if (mainScreen != this.state.big) {
      this.setBigScreen(mainScreen);
    }
  }

  pipLeave() {
    if (this.state.pip) {
      const videoEl = document.querySelector('#' + this.state.pip);
      if (videoEl) videoEl.setAttribute('data-pipped', 'none');
    }
    this.setState({pip: null});
  }

  setPip(e, id) {
    this.pipBigScreen = false;
    e.stopPropagation();

    if (this.state.pip === id) {
      if (document.pictureInPictureElement) { document.exitPictureInPicture() }
    }
    else {
      this.setState({pip: this.state.pip === id ? null : id});
    }

    const videoEl = document.querySelector('#' + id);
    if (videoEl) {
      videoEl.removeEventListener('leavepictureinpicture', this.pipLeft);
      videoEl.requestPictureInPicture()
      .then(_ => {
        this.pipGranted = true;
        videoEl.addEventListener('leavepictureinpicture', this.pipLeft);
        videoEl.setAttribute('data-pipped', 'pipped');
      })
      .catch(e => {
        console.log("PIP denied");
        this.pipGranted = false;
      });
    }
  }

  render() {
    let hasPip = 'pictureInPictureEnabled' in document;
    let mainScreen = this.state.big;

    const liveStreams = this.props.conversation.tracks.filter(r => r.stream && r.stream.active && r.stream.getTracks().some(t => t.readyState === 'live'));

    if (mainScreen === 'local') {
      const localStream = liveStreams.find(s => s.getType() === 'video' && s.isLocal());
      if (localStream) {
        mainScreen = localStream.getId();
      }
    }

    const uniqueTrack = (uniqueTrackMap, track) => {
      const id = getTrackId(track);
      uniqueTrackMap[id] = track;
      return uniqueTrackMap;
    };

    const audioStreams = Object.values(liveStreams.filter(s => s.getType() === 'audio' && !s.isLocal()).reduce(uniqueTrack, {})).map(track => {
      const trackId = getTrackId(track);
      return <div
          key={trackId}
          className={"stream-type-" + track.getType()}
        >
        <audio autoPlay='1' muted={this.props.muteRemote} id={trackId} />
      </div>;
    });

    const videoStreams = Object.values(liveStreams.filter(s => s.getType() === 'video').reduce(uniqueTrack, {})).map(track => {
      const trackId = getTrackId(track);
      const streamId = track.getId();
      const isBigStream = (mainScreen === streamId);

      if (isBigStream && this.pipBigScreen) {
        setTimeout(() => this.setPip({stopPropagation: () => {}}, trackId));
      }

      return (
        <div
          key={trackId}
          className={"stream-type-" + track.getType() + " " + (track.isLocal() ? "localStreamContainer" : "remoteStreamContainer") + (isBigStream ? ' bigStream' : ' smallStream')}
          onClick={() => mainScreen === streamId ? this.toggleZoom() : this.setBigScreen(streamId)}
        >
          <div className={track.isLocal() ? "localStream" : "remoteStream"}>
            <div className="streamoptions"><div className="contactname"><span>{track.isLocal() ? this.props.local.name : ''}</span></div><div className="actions">{hasPip && !isBigStream && <div className="icon" onClick={(e) => this.setPip(e, trackId)}><OpenInNewIcon fill='white' /></div>}</div></div>
            <video 
              data-orientation={track.stream.videoWidth > track.stream.videoHeight ? 'landscape' : 'portrait'}
              autoPlay='1'
              muted={true}
              id={trackId} />
          </div>
        </div>
      );
    });

    return (
      <div className={
          "streams hasremotestream haslocalstream"
          + (this.state.zoomed ? ' zoomed' : '')
        }>
          {audioStreams}
          {videoStreams}
      </div>
    )
  }
};

export default Tracks;