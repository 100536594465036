
import md5 from 'js-md5';

import { getTrackId } from './shared/utils';

const JitsiMeetJS = window.JitsiMeetJS;
const $ = window.$;

function JitsiController(options, roomName) {
  var confOptions = {
    openBridgeChannel: true
  };

  var connection = null;
  var isJoined = false;
  var room = null;

  var isVideo = true;

  var localTracks = [];
  var remoteTracks = {};
  var everyLocalTrackEver = [];
  
  var constraints = {
      video: {
          aspectRatio: 16 / 9,
          height: {
              ideal: 1080,
              max: 1080,
              min: 240
          }
      },
      resolution: 1080
  };

  options = Object.assign({}, options);
  options.roomName = md5.hex(roomName);//.toLowerCase();

  window.addEventListener('beforeunload', unload);
  window.addEventListener('unload', unload);

  // JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
  const initOptions = {
      disableAudioLevels: true,

      // The ID of the jidesha extension for Chrome.
      desktopSharingChromeExtId: 'mbocklcggfhnbahlnepmldehdhpjfcjp',

      // Whether desktop sharing should be disabled on Chrome.
      desktopSharingChromeDisabled: false,

      // The media sources to use when using screen sharing with the Chrome
      // extension.
      desktopSharingChromeSources: [ 'screen', 'window' ],

      // Required version of Chrome extension
      desktopSharingChromeMinExtVersion: '0.1',

      // Whether desktop sharing should be disabled on Firefox.
      desktopSharingFirefoxDisabled: true
  };

  JitsiMeetJS.init(initOptions);
  JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.INFO);
  connection = new JitsiMeetJS.JitsiConnection(null, null, options);

  connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      onConnectionSuccess);
  connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_FAILED,
      onConnectionFailed);
  connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
      disconnect);

  JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      onDeviceListChanged);

  connection.connect();

  JitsiMeetJS.createLocalTracks({ devices: [ 'audio', 'video' ], constraints: constraints })
    .then(logLocalTracks)
      .then(onLocalTracks)
      .catch(error => {
          throw error;
      });

  if (JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
      JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
          const audioOutputDevices
              = devices.filter(d => d.kind === 'audiooutput');

          if (audioOutputDevices.length > 1) {
              $('#audioOutputSelect').html(
                  audioOutputDevices
                      .map(
                          d =>
                              `<option value="${d.deviceId}">${d.label}</option>`)
                      .join('\n'));

              $('#audioOutputSelectWrapper').show();
          }
      });
  }

  function getLocalTrack(options) {
    return JitsiMeetJS.createLocalTracks(options).then(logLocalTracks);
  }

  function logLocalTracks(tracks) {
    everyLocalTrackEver = everyLocalTrackEver.concat(tracks);
    return tracks;
  }

  function createLocalTracks(devices, options) {
      JitsiMeetJS.createLocalTracks(Object.assign({}, { devices: devices || [ 'audio', 'video' ], constraints: constraints }, options || {}))
      .then(logLocalTracks)
      .then(onLocalTracks)
      .catch(error => {
          throw error;
      });
  }

  /**
  * Handles local tracks.
  * @param tracks Array with JitsiTrack objects
  */
  function onLocalTracks(tracks) {
    localTracks = tracks;

    const existingTracks = isJoined ? room.getLocalTracks() : [];

    for (let i = 0; i < localTracks.length; i++) {
        localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
            audioLevel => console.log(`Audio Level local: ${audioLevel}`));
        localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
            () => console.log('local track muted'));
        localTracks[i].addEventListener(
            JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
            () => console.log('local track stoped'));
        localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
            deviceId =>
                console.log(
                    `track audio output device was changed to ${deviceId}`));

        if (options.onLocalTrack) options.onLocalTrack(localTracks[i]);

        if (isJoined) {
            const existingTrackForType = existingTracks.find(t => t.getType() === localTracks[i].getType());
            if (existingTrackForType) {
                room.replaceTrack(existingTrackForType, localTracks[i]);
            }
            else {
                room.addTrack(localTracks[i]);
            }
        }
    }
  }

  /**
  * Handles remote tracks
  * @param track JitsiTrack object
  */
  function onRemoteTrack(track) {
    if (track.isLocal()) {
        return;
    }
    const participant = track.getParticipantId();

    if (!remoteTracks[participant]) {
        remoteTracks[participant] = [];
    }
    const idx = remoteTracks[participant].push(track);

    track.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
        audioLevel => console.log(`Audio Level remote: ${audioLevel}`));
    track.addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        () => console.log('remote track muted'));
    track.addEventListener(
        JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
        () => console.log('remote track stoped'));
    track.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
        deviceId =>
            console.log(
                `track audio output device was changed to ${deviceId}`));
    const id = participant + track.getType() + idx;

    if (options.onRemoteTrack) options.onRemoteTrack(track);
  }

  /**
  * That function is executed when the conference is joined
  */
  function onConferenceJoined() {
    console.log('conference joined!');
    isJoined = true;
    for (let i = 0; i < localTracks.length; i++) {
        room.addTrack(localTracks[i]);
    }

    if (options.onConferenceJoined) options.onConferenceJoined();
  }

  /**
  *
  * @param id
  */
  function onUserLeft(id) {
    console.log('user left');
    if (!remoteTracks[id]) {
        return;
    }
    const tracks = remoteTracks[id];

    for (let i = 0; i < tracks.length; i++) {
        try {
            tracks[i].detach($(`#${getTrackId(tracks[i])}`));
        }
        catch (e) {
            console.log(e);
        }
    }

    if (options.onUserLeft) options.onUserLeft(id, tracks);
  }

  /**
  * That function is called when connection is established successfully
  */
  function onConnectionSuccess() {
    if (options.onConnectionSuccess) options.onConnectionSuccess(connection);
    
  }

  function joinRoom() {
    room = connection.initJitsiConference(options.roomName, confOptions);
    room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
    room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, track => {
        console.log(`track removed!!!${track}`);
    });
    room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        onConferenceJoined);
    room.on(JitsiMeetJS.events.conference.USER_JOINED, id => {
        console.log('user join');
        remoteTracks[id] = [];
    });
    room.on(JitsiMeetJS.events.conference.USER_LEFT, onUserLeft);
    room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, track => {
        console.log(`${track.getType()} - ${track.isMuted()}`);
    });
    room.on(
        JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
        (userID, displayName) => console.log(`${userID} - ${displayName}`));
    room.on(
        JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
        (userID, audioLevel) => console.log(`${userID} - ${audioLevel}`));
    room.on(
        JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED,
        () => console.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`));
    room.join();
    return room;
  }

  /**
  * This function is called when the connection fail.
  */
  function onConnectionFailed() {
    console.error('Connection Failed!');
    if (options.onConnectionFailed) options.onConnectionFailed();
  }

  /**
  * This function is called when the connection fail.
  */
  function onDeviceListChanged(devices) {
    console.info('current devices', devices);
    if (options.onDeviceListChanged) options.onDeviceListChanged();
  }

  /**
  * This function is called when we disconnect.
  */
  function disconnect() {
    console.log('disconnect!');
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        onConnectionSuccess);
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        onConnectionFailed);
    connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        disconnect);
  }

  /**
  *
  */
  function unload() {
    window.removeEventListener('beforeunload', unload);
    window.removeEventListener('unload', unload);

    if (room) room.leave();
    connection.disconnect();
    for (let i = 0; i < everyLocalTrackEver.length; i++) {
        everyLocalTrackEver[i].dispose();
    }
    
  }

  /**
  *
  */
  function switchVideo() { // eslint-disable-line no-unused-vars
    isVideo = !isVideo;
    if (localTracks[1]) {
        localTracks[1].dispose();
        localTracks.pop();
    }
    JitsiMeetJS.createLocalTracks({
        devices: [ isVideo ? 'video' : 'desktop' ],
        constraints: constraints,
        resolution: 1080
    })
        .then(logLocalTracks)
        .then(tracks => {
            localTracks.push(tracks[0]);
            localTracks[1].addEventListener(
                JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
                () => console.log('local track muted'));
            localTracks[1].addEventListener(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                () => console.log('local track stoped'));
            room.addTrack(localTracks[1]);
        })
        .catch(error => console.log(error));
  }

  /**
  *
  * @param selected
  */
  function changeAudioOutput(id) { // eslint-disable-line no-unused-vars
    JitsiMeetJS.mediaDevices.setAudioOutputDevice(id);
  }

  function getRoom() {
      return room;
  }

  return {
      unload,
      switchVideo,
      disconnect,
      connection,
      getRoom,
      joinRoom,
      changeAudioOutput,
      createLocalTracks,
      getLocalTrack,
      remoteTracks,
      localTracks,
  };
}

export default JitsiController;
