import React, { Component } from 'react';

class ProviderLogo extends Component {
  render() {
    return (
      <a style={this.props.style||{}} target="_blank" className="providerlogo" href="https://www.safechat.pro">
        <img alt="Provided by safechat.pro" src="providedby.png" style={{height: '1.2rem'}} />
      </a>
    );
  }
}

export default ProviderLogo;