
const muiTheme = {
  palette: {
    // primary: {main: 'rgb(0, 154, 60)'},
    primary: { main: 'rgb(230, 52, 26)' },
    secondary: { main: 'rgb(217, 29, 18)' },
    pickerHeaderColor: {main: 'rgb(217, 29, 18)'}
  }
};

export default muiTheme;