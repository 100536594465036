import React, { Component } from 'react';
import ContentSend from '@material-ui/icons/Send';
import OpenInNew from '@material-ui/icons/OpenInNew';
import FileCopy from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'

import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/de";
import frLocale from "date-fns/locale/fr";
import itLocale from "date-fns/locale/it";

import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';

import areIntlLocalesSupported from 'intl-locales-supported';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

import { Tooltip } from '@material-ui/core';

const localeMessages = defineMessages({
  appointment: {
    id: 'Supporter.AppointmentForm.Appointment',
    defaultMessage: 'Ihr Online-Termin mit {name}'
  },
  clickchat: {
    id: 'Supporter.AppointmentForm.ClickChat',
    defaultMessage: 'Zum chatten hier klicken: '
  },
  startchat: {
    id: 'Supporter.AppointmentForm.StartChat',
    defaultMessage: 'Chat starten'
  },
  cancel: {
    id: 'Supporter.AppointmentForm.Cancel',
    defaultMessage: 'Abbrechen'
  },
  sendinvite: {
    id: 'Supporter.AppointmentForm.SendInvite',
    defaultMessage: 'Einladung senden'
  },
  sendtoself: {
    id: 'Supporter.AppointmentForm.SendToSelf',
    defaultMessage: 'Link an meine E-Mail-Adresse senden'
  },
  sendtoselfbutton: {
    id: 'Supporter.AppointmentForm.SendToSelfButton',
    defaultMessage: 'Senden'
  },
  copybutton: {
    id: 'Supporter.AppointmentForm.CopyButton',
    defaultMessage: 'Kopieren'
  },
  copybuttonTooltip: {
    id: 'Supporter.AppointmentForm.CopyButtonTooltip',
    defaultMessage: 'Link in die Zwischenablage kopieren'
  },
  previewAnonymousLinkTooltip: {
    id: 'Supporter.AppointmentForm.PreviewAnonymousLinkTooltip',
    defaultMessage: 'Vorschau aus Kundensicht anzeigen'
  },
  previewAnonymousLink: {
    id: 'Supporter.AppointmentForm.PreviewAnonymousLink',
    defaultMessage: 'Vorschau'
  },
  chatlinktitle: {
    id: 'Supporter.AppointmentForm.ChatlinkTitle',
    defaultMessage: 'Sie sind unter folgendem Link erreichbar:'
  },
  chatlinkwithappointment: {
    id: 'Supporter.AppointmentForm.ChatlinkWithAppointment',
    defaultMessage: 'Link mit Termin generieren:'
  },
  choosedate: {
    id: 'Supporter.AppointmentForm.ChooseDate',
    defaultMessage: 'Wählen Sie ein Datum'
  },
  choosetime: {
    id: 'Supporter.AppointmentForm.ChooseTime',
    defaultMessage: 'Wählen Sie eine Uhrzeit'
  },
  ok: {
    id: 'Supporter.AppointmentForm.OK',
    defaultMessage: 'OK'
  },
  sendinvitemail: {
    id: 'Supporter.AppointmentForm.SendInviteMail',
    defaultMessage: 'Einladung versenden'
  },
  mailrecipient: {
    id: 'Supporter.AppointmentForm.MailRecipient',
    defaultMessage: 'Empfänger'
  },
  mailsubject: {
    id: 'Supporter.AppointmentForm.MailSubject',
    defaultMessage: 'Betreff'
  },
  mailremarks: {
    id: 'Supporter.AppointmentForm.MailRemarks',
    defaultMessage: 'Spezielle Anmerkungen'
  },
  mailpreview: {
    id: 'Supporter.AppointmentForm.MailPreview',
    defaultMessage: 'E-Mail Vorschau'
  }
});

const localeMap = {
  de: deLocale,
  fr: frLocale,
  it: itLocale,
};

class LinkDisplay extends Component {
  linkText = null;
  groupName = null;
  dateTimeFormat = null;
  
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  constructor(props) {
    super(props);
    this.linkText = React.createRef();
    this.groupName = React.createRef();
    this.state = this.getInitialState();
  }

  componentWillMount() {
    if (areIntlLocalesSupported([this.props.locale])) {
      this.dateTimeFormat = global.Intl.DateTimeFormat;
    } else {
      const IntlPolyfill = require('intl');
      this.dateTimeFormat = IntlPolyfill.DateTimeFormat;
      require('intl/locale-data/jsonp/de');
      require('intl/locale-data/jsonp/fr');
      require('intl/locale-data/jsonp/it');
    }
  }

  componentDidUpdate(pP) {
    if (this.props.asGroup && pP.disabled && !this.props.disabled) {
      this.groupName.current.querySelector('input').focus();
    }
  }

  getInitialState() {
    return {
      appDate: null,
      appTime: null,
      groupName: '',
      withAppointment: false,
      withId: false,
      name: ''
    };
  }

  getInviteLink() {
    var d = this.state.appDate ? this.state.appDate : new Date();
    var t = this.state.appTime ? this.state.appTime : new Date();
    d.setHours(t.getHours());
    d.setMinutes(t.getMinutes());
    d.setSeconds(t.getSeconds());
    
    return this.props.getInviteLink(this.state.withAppointment ? d : null, this.state.withAppointment ? t : null, this.props.asGroup ? this.state.groupName : null, null, this.state.name ? this.state.name : null);
  }

  sendInviteToUser() {
    this.props.sendInviteToUser(this.getInviteLink(), this.state);
  }

  copyLink() {
    this.props.copyLink(this.linkText.current);
  }

  preview(e, url) {
    this.props.preview(e.screenX, e.screenY, url);
  }

  render() {
    return (
      <div>
        {this.props.asGroup && <TextField
          value={this.state.groupName}
          margin="dense"
          variant="outlined"
          disabled={this.props.disabled}
          fullWidth
          onInput={(e) => this.setState({groupName: e.target.value})}
          style={{marginBottom: '1em'}}
          helperText={<FormattedMessage
            id="Supporter.AppointmentForm.GroupNameDescription"
            defaultMessage="Der Gruppenname wird allen Teilnehmern angezeigt."
          />}
          InputLabelProps={{
            shrink: true,
          }}
          label={<FormattedMessage
            id="Supporter.AppointmentForm.GroupName"
            defaultMessage="Gruppenname"
          />}
          ref={this.groupName}
        />}
        <div style={{display:'flex', marginBottom:'0.25em'}}>
          <TextField
            disabled={this.props.disabled}
            ref={this.linkText}
            name="invitelink"
            value={this.getInviteLink()}
            onClick={(e) => e.target.select()}
            variant="outlined"
            margin="dense"
            fullWidth
            label={<FormattedMessage
              id="Supporter.AppointmentForm.ConversationLink"
              defaultMessage="Gesprächslink"
            />}
            InputProps={{style: {textAlign:'center'}}}
            helperText={!this.props.asGroup ?
              <FormattedMessage
              id="Supporter.AppointmentForm.ChatlinkDescription"
                defaultMessage="Über den anonymen Gesprächslink kann man Sie jederzeit direkt für Videochat erreichen." /> : 
              <FormattedMessage
                id="Supporter.AppointmentForm.GroupChatlinkDescription"
                defaultMessage="Über diesen Link können Teilnehmer am Gruppengespräch teilnehmen."
            />}
          />
        </div>
        <FormControl margin="dense">
          <Tooltip title={this.context.intl.formatMessage(localeMessages.copybuttonTooltip)}>
            <span>
              <Button
                disabled={this.props.disabled}
                onClick={() => this.copyLink()}
                color="primary"
                variant="outlined"
                style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
              >
                <FileCopy style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                {this.context.intl.formatMessage(localeMessages.copybutton)}
              </Button>
            </span>
          </Tooltip>
        </FormControl>
        <FormControl margin="dense">
          <Tooltip title={this.context.intl.formatMessage(localeMessages.sendtoself)}>
            <span>
              <Button
                disabled={this.props.disabled}
                onClick={() => this.sendInviteToUser()}
                color="primary"
                variant="outlined"
                style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
              >
                <ContentSend style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                {this.context.intl.formatMessage(localeMessages.sendtoselfbutton)}
              </Button>
            </span>
          </Tooltip>
        </FormControl>
        <FormControl margin="dense">
          <Tooltip title={this.context.intl.formatMessage(localeMessages.previewAnonymousLinkTooltip)}>
            <span>
              <Button
                disabled={this.props.disabled}
                onClick={(e) => this.preview(e, this.getInviteLink())}
                color="primary"
                variant="outlined"
                style={{whiteSpace: 'nowrap', marginLeft: '5px'}}
              >
                <OpenInNew style={{height: '0.75em', width: '0.75em', marginRight: '0.1em'}}/>
                {this.context.intl.formatMessage(localeMessages.previewAnonymousLink)}
              </Button>
            </span>
          </Tooltip>
        </FormControl>
        <div style={{marginTop: '0.5em'}}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={this.props.disabled}
                checked={this.state.withAppointment}
                onChange={() => this.setState({withAppointment: !this.state.withAppointment})}
                value={true} />
            }
            label={<FormattedMessage
              id="Supporter.AppointmentForm.WithAppointment"
              defaultMessage="Mit Terminanzeige"
            />}
          />
          {!this.props.disabled && this.state.withAppointment && <div>
            <FormHelperText style={{marginLeft: '2.65em', marginTop: '-0.5em', marginBottom: '0.5em'}}>
              <FormattedMessage
                id="Supporter.AppointmentForm.WithAppointmentDescription"
                defaultMessage="Die Terminanzeige zeigt Ihren Teilnehmern den Startzeitpunkt des Gesprächs an."
              />
            </FormHelperText>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[this.props.locale]}>
              <DatePicker
                onChange={(d) => this.setState({appDate: d})}
                format='dd.MM.yyyy'
                okLabel={this.context.intl.formatMessage(localeMessages.ok)}
                cancelLabel={this.context.intl.formatMessage(localeMessages.cancel)}
                value={this.state.appDate}
                InputProps={{style: {textAlign:'center'}}}
                fullWidth
                margin="dense"
                style={{maxWidth:'15em', marginLeft: '2em', marginBottom: '1em'}}
                label={
                  <FormattedMessage
                    id="Supporter.AppointmentForm.DateLabel"
                    defaultMessage="Datum"
                  />}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={this.context.intl.formatMessage(localeMessages.choosedate)}
              />
              <TimePicker
                label={
                  <FormattedMessage
                    id="Supporter.AppointmentForm.TimeLabel"
                    defaultMessage="Uhrzeit"
                  />}
                ampm={false}
                okLabel={this.context.intl.formatMessage(localeMessages.ok)}
                cancelLabel={this.context.intl.formatMessage(localeMessages.cancel)}
                onChange={(d) => this.setState({appTime: d})}
                value={this.state.appTime}
                fullWidth
                margin="dense"
                InputProps={{style: {textAlign:'center'}}}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{maxWidth:'15em', marginLeft: '2em', marginBottom: '1em'}}
                placeholder={this.context.intl.formatMessage(localeMessages.choosetime)} />
            </MuiPickersUtilsProvider>
          </div>}
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={this.props.disabled}
                checked={this.state.withId}
                onChange={() => this.setState({withId: !this.state.withId})}
                value={true} />
            }
            label={<FormattedMessage
              id="Supporter.AppointmentForm.WithIdentification"
              defaultMessage="Mit Identifikation"
            />}
          />
          {!this.props.disabled && this.state.withId && <div style={{marginLeft: '2em', marginTop: '0.5em'}}>
            <TextField
              value={this.state.name}
              margin="dense"
              variant="outlined"
              disabled={this.props.disabled}
              fullWidth
              onInput={(e) => this.setState({name: e.target.value})}
              style={{marginBottom: '1em'}}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                <FormattedMessage
                  id="Supporter.AppointmentForm.WithIDDescription"
                  defaultMessage="Die Identifikation wird allen angezeigt, sollte der Link benutzt werden. Dies kann z.B. ein Name sein."
                />}
              label={<FormattedMessage
                id="Supporter.AppointmentForm.ID"
                defaultMessage="Identifikation"
              />}
            />
          </div>}
        </div>
      </div>
    );
  }
}

class AppointmentForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      regularChatLink: true
    }
  }

  render() {
    return (
      <div>
        <div style={{display: 'flex', maxWidth:"60em", marginBottom:'1.5em'}}>
          <div style={{width: '15em'}}>
            <FormControl margin="dense">
              <FormControlLabel
                control={
                  <Radio
                    checked={this.state.regularChatLink}
                    onChange={(e) => this.setState({
                      regularChatLink: e.target.checked
                    })}
                  />
                }
                label={<span style={{fontWeight:'bolder'}}>
                  <FormattedMessage
                    id="Supporter.AppointmentForm.ConversationTypeDirect"
                    defaultMessage="Anonymes Gespräch"
                  />
                </span>}
              />
            </FormControl>
          </div>
          <div style={{flex: 1}}>
            <LinkDisplay
              locale={this.props.locale}
              getInviteLink={this.props.getInviteLink}
              sendInviteToUser={this.props.sendInviteToUser}
              copyLink={this.props.copyLink}
              preview={this.props.preview}
              asGroup={false}
              disabled={!this.state.regularChatLink}
            />
          </div>
        </div>
        <Divider />
        <div style={{display: 'flex', maxWidth:"60em", marginTop: '1em'}}>
          <div style={{width: '15em'}}>
            <FormControl margin="dense">
              <FormControlLabel
                control={
                  <Radio
                    checked={!this.state.regularChatLink}
                    onChange={(e) => this.setState({
                      regularChatLink: !e.target.checked
                    })}
                  />
                }
                label={<span style={{fontWeight:'bolder'}}>
                  <FormattedMessage
                    id="Supporter.AppointmentForm.ConversationTypeGroup"
                    defaultMessage="Anonymes Gruppengespräch"
                  />
                </span>}
              />
            </FormControl>
          </div>
          <div style={{flex: 1}}>
            <LinkDisplay
              locale={this.props.locale}
              getInviteLink={this.props.getInviteLink}
              sendInviteToUser={this.props.sendInviteToUser}
              copyLink={this.props.copyLink}
              preview={this.props.preview}
              asGroup={true}
              disabled={this.state.regularChatLink}
            />
          </div>
        </div>
      </div>
    )
  }
};

export default AppointmentForm;
