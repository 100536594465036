import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { FormattedMessage, intlShape, formatMessage } from 'react-intl';

import config from './config';

const moment = window.moment;

const preDefinedFields = {
  'personal': ['firstname', 'lastname', 'dob', 'sex'],
  'communication': ['tel'],
  'address': ['street', 'no', 'postal', 'city', 'country', ],
  'insurance': ['insurance', 'insuranceCardNo']
};

const translateValues = {
  'metadata.personal.sex': ['male', 'female']
};

function getTotalTime(events) {
  let totalTime = 0;
  let lastAccepted = null;
  events.forEach(e => {
    if (e.type === 'accepted') lastAccepted = e;
    if (e.type === 'callend' && lastAccepted) {
      totalTime += Math.abs(moment(lastAccepted.time).diff(moment(e.time)));
    }
  });
  return totalTime;
}

function getTextMetadata(clientMetadata, intl) {
  const { config, values } = clientMetadata;

  const sections = config.map(s => {
    let fields = (preDefinedFields[s.type] || Object.keys(values[s])).map(field => {
      const translationKey = `metadata.${s.type}.${field}`;
      const readableField = intl.formatMessage({id: translationKey, defaultMessage: field});
      let value = (values[s.id] || {})[field] || '';

      if (translationKey in translateValues && translateValues[translationKey].indexOf(value) !== -1) {
        value = intl.formatMessage({id: `${translationKey}.${value}`, defaultMessage: value});
      }

      return `${readableField}: ${value}`;
    });
    
    return fields.join("\n");
  });

  return sections.join("\n");
}

function getChatData(messages, local, conversation, intl) {
  messages = messages.filter(m => "text" in m).sort((a, b)=> a.time.getTime() - b.time.getTime())
  return messages.map((m, i) => {
    const isLocal = (m.from === local.id) || m.from.id === local.id;
    const author = (isLocal ? local.name : conversation.name || 'Anonymous').trim();
    const text = m.text;

    const showAuthor = (i === 0 || messages[i - 1].from !== m.from);
    return (showAuthor ? `\n${author}:\n` : '') +
    `${text}`;
  }).join("\n");
}


function _sendConversation(props, intl) {
  const { events, sent, sendMail, conversation, user } = props;
  const online = (events.find(e => e.type === 'online') || {}).time;
  const offline = (events.find(e => e.type === 'offline') || {}).time;
  const firstCall = (events.find(e => e.type === 'accepted') || {}).time;
  const totalTime = getTotalTime(events);

  var maildata = {
    from: config.mailsender,
    to: user.email,
    subject: intl.formatMessage({
      id: 'Supporter.SummarySubject',
      defaultMessage: '{appname} – Gesprächszusammenfassung'},
      {
        appname: config.appName
      }),
    text: intl.formatMessage({
      id: 'Supporter.Summary',
      defaultMessage: "Gespräch mit {client} am {date}\n\n"
        + "Online: {online}\n"
        + "Offline: {offline}\n"
        + "Erster Anruf: {firstCall}\n"
        + "Gesprächszeit insgesamt: {totalTime}\n\n"
        + "Metadata:\n{metadata}\n\n"
        + "Chat:\n{chat}"
    }, {
      client: conversation.name,
      date: moment(online).format('DD.MM.Y'),
      online: online ? moment(online).format('HH:mm:ss') : '',
      offline: offline ? moment(offline).format('HH:mm:ss') : '',
      firstCall: firstCall ? moment(firstCall).format('HH:mm:ss') : '',
      totalTime: moment.utc(totalTime).format('HH:mm:ss'),
      metadata: conversation.clientMetadata ? getTextMetadata(conversation.clientMetadata, intl) : '',
      chat: getChatData(conversation.messages, user, conversation, intl)
    })
  };
  
  sendMail(maildata);
}

class SaveConversation extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    mailsent: false
  }

  sendSummary() {
    const { events, sent, sendMail, conversation, user } = this.props;
    _sendConversation({ events, sent, sendMail, conversation, user }, this.context.intl);
    this.setState({mailsent: true});
  }

  render() {
    const { events, sent, sendMail, conversation, user } = this.props;

    const totalTime = getTotalTime(events);

    return <div className="message event remote" style={{
        color: '#666',
        textAlign: 'center',
        maxWidth: '45em',
        fontSize: '90%',
        margin: 'auto',
        lineHeight: '1.4em',
      }}>
      <p>
        <FormattedMessage
          id="Supporter.SaveConversation.TalkingTotal"
          defaultMessage="Gesprächsdauer insgesamt:"
        /> {moment.utc(totalTime).format('HH:mm:ss')}<br/>
        <FormattedMessage
          id="Supporter.SaveConversation.Description"
          defaultMessage="Sie können sich eine Zusammenfassung dieses Gesprächs per E-Mail zukommen lassen."
        />
      </p>
      <Button onClick={() => this.sendSummary()} color="primary">{
        this.state.mailsent ?
        <FormattedMessage
          id="Supporter.SaveConversation.ReSend"
          defaultMessage="Gesprächsdaten erneut senden"
        /> :
        <FormattedMessage
          id="Supporter.SaveConversation.Send"
          defaultMessage="Gesprächsdaten zusenden"
        />
        }</Button>
    </div>;
  }
};

export const sendConversation = _sendConversation;
export default SaveConversation;