import React, { Component } from 'react';

let visibilityChange, hidden;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

class Video extends Component {
  timeout = null;
  videoTrackCount = 0;
  visibilityHandler = null;
  pipGranted = false;

  constructor(props) {
    super(props);

    this.pipLeft = () => this.pipLeave();
  }

  shouldComponentUpdate(nextProps) {
    if (!this.refs.stream || !this.refs.stream.srcObject) return true;

    if (nextProps.stream && this.refs.stream.srcObject && nextProps.stream.id !== this.refs.stream.srcObject.id) {
      return true;
    }
    
    if (!nextProps.stream) {
      return true;
    }

    if (nextProps.stream.getVideoTracks().length !== this.videoTrackCount) {
      return true;
    }

    if (nextProps.muted !== this.props.muted) {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('muted', nextProps.muted);
      }
      return false;
    }

    if (this.refs.stream && nextProps.pip && !this.props.pip) {
      if (document.pictureInPictureElement) { document.exitPictureInPicture() }

      this.refs.stream.removeEventListener('leavepictureinpicture', this.pipLeft);
      this.refs.stream.requestPictureInPicture()
      .then(_ => {
        this.pipGranted = true;
        this.refs.stream.addEventListener('leavepictureinpicture', this.pipLeft);
        this.refs.stream.setAttribute('data-pipped', 'pipped');
      })
      .catch(e => {
        console.log("PIP denied");
        this.pipGranted = false;
      });
    }

    return false;
  }

  pipLeave() {
    this.props.onLeavePip();
    if (this.refs.stream) this.refs.stream.setAttribute('data-pipped', 'none');
  }

  componentDidMount() {
    this.refs.stream.addEventListener('playing', () => {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('data-orientation', this.refs.stream.videoWidth > this.refs.stream.videoHeight ? 'landscape' : 'portrait');
      }
    });

    /*
    this.visibilityHandler = () => {
      if (this.refs.stream && this.props.pip && 'pictureInPictureEnabled' in document) {
        if (document[hidden]) {
          this.refs.stream.requestPictureInPicture()
          .then(_ => {
            this.pipGranted = true;
          })
          .catch(e => {
            console.log("PIP denied");
            this.pipGranted = false;
          });
        }
        else {
          if (document.pictureInPictureElement) { document.exitPictureInPicture() }
        }
      }
    };

    document.addEventListener(visibilityChange, this.visibilityHandler, false);
    */
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.visibilityHandler);
  }


  componentDidUpdate() {
    let nextProps = this.props;

    if (!this.props.stream) {
      clearTimeout(this.timeout);
      return;
    }

    let setStream = () => {
      this.refs.stream.srcObject = nextProps.stream;

      if (this.props.muted) {
        this.refs.stream.setAttribute('muted', this.props.muted);
      }
      else {
        this.refs.stream.removeAttribute('muted');
      }
      this.refs.stream.setAttribute('playsinline', '1');
      this.refs.stream.setAttribute('autoplay', '1');

      let tracks = nextProps.stream.getVideoTracks();
      this.videoTrackCount = tracks.length;
      let id = tracks.length ? tracks[0].id : 'no tracks';
      console.log(this.props.className, "Reading", id);

      const playPromise = this.refs.stream.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("ERROR", id, error);
        });
      }

      nextProps.stream.onaddtrack = (e) => { console.log("Stream track added"); };
      console.log("Added stream", nextProps.stream);
    }

    setStream();

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      if (this.refs.stream && this.refs.stream.currentTime === 0) {
        // revert to stream before
        console.log("Not playing");
        this.props.onError();
      }
    }, 2000);
  }

  render() {
    let props = {...this.props};
    delete props.pip;
    delete props.onLeavePip;
    return (<video {...props} ref="stream" playsInline></video>);
  }
};

export default Video;